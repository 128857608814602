.datePicker {
  --rdp-cell-size: 45px !important;
  --rdp-caption-font-size: 14px !important;
  --rdp-accent-color: theme('colors.tertiary500') !important;
  --rdp-background-color: theme('colors.tertiary200') !important;
  --rdp-outline: 2px solid var(--rdp-accent-color);
  --rdp-outline-selected: 3px solid var(--rdp-accent-color);

  box-shadow: theme('boxShadow.3');
  margin: theme('spacing.3') 0 0 0 !important;
  border: 1px solid theme('colors.tertiary50');
  border-radius: theme('borderRadius.5');
  padding: theme('spacing.6');
  background-color: theme('colors.primary25');

  :global {
    .rdp-cell {
      height: 45px;
      width: 45px;

      & button {
        @apply text-caption-lg-regular;
        color: theme('colors.tertiary500');
      }
    }

    .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
      background-color: transparent;
      border: 1px solid theme('colors.tertiary200');
    }

    .rdp-button:focus-visible:not([disabled]) {
      outline: none;
      border: none;
      box-shadow: theme('boxShadow.0');
    }

    .rdp-day_selected {
      color: theme('colors.primary25') !important;
      background-color: theme('colors.tertiary500') !important;

      &.rdp-day_outside {
        background-color: transparent !important;
      }
    }

    .rdp-day_disabled {
      cursor: not-allowed;
    }

    .rdp-day_outside {
      color: theme('colors.tertiary200') !important;
    }

    .rdp-caption_label {
      @apply text-heading-h6-respon;
      color: theme('colors.tertiary500');
    }

    .rdp-head_row {
      .rdp-head_cell {
        @apply text-caption-lg-bold;
        text-transform: capitalize;
        color: theme('colors.tertiary500');
      }
    }

    .rdp-nav_button_previous {
      position: absolute;
      left: 0;
    }

    .rdp-caption_label {
      margin: 0 auto;
    }

    .rdp-nav_button_next {
      position: absolute;
      right: 0;
    }

    .rdp-caption {
      height: 40px;
      position: relative;
    }

    .rdp-nav {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      height: 100%;
      align-items: center;
      display: flex;
    }

    .rdp-table {
      margin-top: theme('spacing.4');
    }

    .rdp-day_range_middle {
      background-color: theme('colors.tertiary25') !important;
      color: theme('colors.tertiary500') !important;
      font-weight: 600 !important;
      overflow: visible;

      &:hover {
        background-color: theme('colors.tertiary200');
        color: theme('colors.tertiary200');
        border-radius: 100% !important;
      }

      &::after {
        content: '';
        height: 45px;
        width: 45px;
        left: 0;
        background-color: theme('colors.tertiary25');
        z-index: -1;
        position: absolute;
      }
    }

    .rdp-day_range_start {
      border-radius: 100% !important;
      overflow: visible;

      &::after {
        content: '';
        height: 45px;
        width: 50%;
        right: -5px;
        background-color: theme('colors.tertiary25');
        z-index: -1;
        position: absolute;
      }
    }

    .rdp-day_range_end {
      border-radius: 100% !important;
      overflow: visible;

      &::after {
        content: '';
        height: 45px;
        width: 50%;
        left: -5px;
        background-color: theme('colors.tertiary25');
        z-index: -1;
        position: absolute;
      }
    }
  }
}

.textfield {
  cursor: pointer !important;
}
