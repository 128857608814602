.badge {
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: theme('colors.primary25');
  font-weight: 500 !important;

  &:empty {
    padding: 0 !important;
  }
}

.badge svg {
  stroke-width: 2px;
}

.iconClose {
  cursor: pointer;
}

[data-only-icon='true'] {
  &.badge {
    padding: 0 !important;
    justify-content: center;
  }
}

[data-only-icon='true'].badge svg {
  margin: 0 !important;
}

[data-size='small'] {
  &.badge {
    height: 21px;
    border-radius: 10.5px;
    padding: 0 theme('spacing.1');
    @apply text-caption-lg-semibold;

    .iconLeft {
      margin-right: 4px;
    }

    .iconRight {
      margin-left: 4px;
    }

    &[data-only-icon='true'] {
      width: 21px;
    }

    &:empty {
      height: theme('spacing.2');
      width: theme('spacing.2');
      border-radius: calc(theme('spacing.2') / 2);
    }
  }
}

[data-size='small'].badge svg {
  width: 12px;
  max-height: 12px;
}

[data-size='medium'] {
  &.badge {
    height: 28px;
    border-radius: 14px;
    padding: 0 theme('spacing.2');
    @apply text-body-sm-semibold;

    .iconLeft {
      margin-right: 6px;
    }

    .iconRight {
      margin-left: 6px;
    }

    &[data-only-icon='true'] {
      width: 28px;
    }

    &:empty {
      height: theme('spacing.3');
      width: theme('spacing.3');
      border-radius: calc(theme('spacing.3') / 2);
    }
  }
}

[data-size='medium'].badge svg {
  width: 16px;
  max-height: 16px;
}

[data-size='large'] {
  &.badge {
    height: 32px;
    border-radius: 16px;
    padding: 0 theme('spacing.3');
    @apply text-body-lg-semibold;

    .iconLeft {
      margin-right: 6px;
    }

    .iconRight {
      margin-left: 6px;
    }

    &[data-only-icon='true'] {
      width: 32px;
    }

    &:empty {
      height: theme('spacing.4');
      width: theme('spacing.4');
      border-radius: calc(theme('spacing.4') / 2);
    }
  }
}

[data-size='large'].badge svg {
  width: 16px;
  max-height: 16px;
}

[data-color='primary'] {
  &.badge {
    background-color: theme('colors.primary500');
    color: theme('colors.primary25');
  }
}

[data-color='secondary'] {
  &.badge {
    background-color: theme('colors.secondary500');
    color: theme('colors.primary25');
  }
}

[data-color='tertiary'] {
  &.badge {
    background-color: theme('colors.tertiary50');
    color: theme('colors.tertiary500');
  }
}

[data-color='info'] {
  &.badge {
    background-color: theme('colors.information50');
    color: theme('colors.information500');
  }
}

[data-color='success'] {
  &.badge {
    background-color: theme('colors.success50');
    color: theme('colors.success500');
  }
}

[data-color='warning'] {
  &.badge {
    background-color: theme('colors.warning50');
    color: theme('colors.warning500');
  }
}

[data-color='error'] {
  &.badge {
    background-color: theme('colors.error50');
    color: theme('colors.error500');
  }
}
