.label {
  @apply text-caption-lg-semibold;
  text-align: left;
  word-break: break-all;
  margin-bottom: theme('spacing.2');
  color: theme('colors.tertiary500');
}

.caption {
  @apply text-caption-sm-regular;
  text-align: left;
  word-break: break-all;
  margin-top: theme('spacing.2');
  color: theme('colors.tertiary300');
}

.iconLeft {
  width: theme('spacing.5');
  max-height: theme('spacing.5');
  margin-right: theme('spacing.3');
}

[data-variant='success'] {
  .caption {
    color: theme('colors.success500');
  }

  .select {
    :global {
      .select__control {
        border-color: theme('colors.success500');
      }
    }
  }
}

[data-variant='error'] {
  .caption {
    color: theme('colors.error500');
  }

  .select {
    :global {
      .select__control {
        border-color: theme('colors.error500');
      }
    }
  }
}

.select {
  @apply text-caption-lg-regular;
  color: theme('colors.tertiary500');

  /* Container */
  /* ---------------- */
  &:global(.select--is-disabled) {
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      cursor: not-allowed;
      top: 0;
      left: 0;
      pointer-events: auto;
    }
  }

  /* https://react-select.com/components#components */
  :global {
    /* Control */
    /* ---------------- */
    .select__control {
      @apply text-caption-lg-regular;
      padding: 0;
      background-color: transparent;
      flex: 1 1 0%;
      border-style: none;
      outline: 0;
      border: 1px solid theme('colors.tertiary50');
      border-radius: theme('borderRadius.4');
      box-sizing: border-box;
      height: 40px;
      padding: theme('spacing.2') theme('spacing.3');

      &:hover {
        border-color: theme('colors.tertiary200');
      }
    }

    .select__control--is-disabled {
      background-color: theme('colors.tertiary25');
      border-color: theme('colors.tertiary200') !important;
      color: theme('colors.tertiary200') !important;

      .select__dropdown-indicator {
        color: theme('colors.tertiary200');
      }
    }

    .select__control--is-focused {
      border-color: theme('colors.tertiary200');
      box-shadow: theme('boxShadow.0');
    }

    .select__control--menu-is-open {
      .select__dropdown-indicator {
        transform: rotate(180deg);
      }
    }

    /* Indicator */
    /* ---------------- */
    .select__dropdown-indicator {
      color: theme('colors.tertiary500');
    }

    /* Menu */
    /* ---------------- */
    .select__menu {
      max-height: 320px;
      background-color: theme('colors.primary25');
      box-shadow: theme('boxShadow.3');
      overflow-x: hidden;
      overflow-y: auto;
    }

    /* Option */
    /* ---------------- */
    .select__option {
      width: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: theme('spacing.2') theme('spacing.3');

      & span {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:hover {
        background-color: theme('colors.tertiary25');
        font-weight: 600;
      }
    }

    .select__option-wrapper {
      display: flex;
      flex-direction: column;
    }

    .select__option-icon {
      margin-right: theme('spacing.2');
    }

    .select__option-checkbox {
      position: relative;
      display: none;
    }

    .select__option-description {
      @apply text-caption-sm-regular;
      color: theme('colors.tertiary400');
    }

    .select__option--is-disabled {
      cursor: not-allowed;
      font-weight: 400 !important;
      color: theme('colors.tertiary200');

      .select__option-description {
        color: theme('colors.tertiary200');
      }

      &:hover {
        background-color: unset;
      }
    }

    .select__option--is-selected {
      font-weight: 600;
    }

    /* Placeholder */
    /* ---------------- */
    .select__placeholder {
      color: theme('colors.tertiary200');
      white-space: nowrap;
    }
  }
}

.multiValuePills {
  display: flex;
  align-items: center;
  row-gap: 2px;
  border-radius: theme('borderRadius.5');
  background-color: theme('colors.tertiary50');
  color: theme('colors.tertiary500');
  font-weight: 600;
  padding: 0 4px 1px 4px;
  font-size: 14px;
  margin-right: 4px;
  color: var(--tertiary-500, #344054);
  text-align: center;
  font-style: normal;
  line-height: 21px;
}

.multiValueRemove {
  margin-top: 3px;
}
