.wrapper {
  display: inline-block;
  position: relative;
  overflow-wrap: break-word;
}

.icon {
  box-sizing: border-box;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  pointer-events: none;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: theme('borderRadius.2');
  box-shadow: theme('boxShadow.2');
  color: theme('colors.primary25');
}

.caption {
  display: block;
  margin-left: theme('spacing.2');
  color: theme('colors.tertiary500');
  text-align: left;
  word-break: break-word;
}

.input {
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }

  &:not(:checked):not(:indeterminate) + span {
    border-color: theme('colors.tertiary300');
  }

  &:not(:checked):not(:indeterminate) + span svg {
    display: none;
  }

  &:checked + span,
  &:indeterminate + span {
    border-color: theme('colors.primary25');
    background-color: theme('colors.secondary500');
  }

  &:disabled ~ .caption {
    color: theme('colors.tertiary200');
  }

  &:disabled + span {
    background-color: theme('colors.tertiary25');
    border-color: theme('colors.tertiary200');
  }

  &:checked:disabled + span,
  &:indeterminate:disabled + span {
    background-color: theme('colors.secondary100');
    border-color: theme('colors.secondary100');
  }
}

[data-size='small'] {
  &.wrapper {
    min-width: theme('spacing.4');
    min-height: theme('spacing.4');
    padding-left: theme('spacing.4');
  }

  .icon {
    width: theme('spacing.4');
    height: theme('spacing.4');
  }

  .caption {
    @apply text-caption-sm-regular;
    line-height: theme('spacing.4');
  }
}

[data-size='large'] {
  &.wrapper {
    min-width: theme('spacing.6');
    min-height: theme('spacing.6');
    padding-left: theme('spacing.6');
  }

  .icon {
    width: theme('spacing.6');
    height: theme('spacing.6');
  }

  .caption {
    @apply text-caption-lg-regular;
    line-height: theme('spacing.6');
  }
}
