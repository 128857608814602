.inputFrom [data-element='addon-right'] {
  @apply text-caption-lg-regular;
  background-color: white;
  margin-left: -24px !important;
}

.inputFrom input {
  width: 162px;
  margin-left: 8px;
}

.inputTo {
  display: inline-flex;
  align-items: center;
  color: theme('colors.tertiary500');
  width: 100%;
}

.inputTo input {
  @apply text-caption-lg-regular;
  margin-left: theme('spacing.3');
  outline: none;
  border: 0;
}

.dateRangePickerPreset {
  width: 100%;
  position: absolute;
  top: 26px;
  width: 156px;
  left: 18px;

  & > div {
    @apply text-caption-lg-regular;
    cursor: pointer;
    height: 36px;
    padding: theme('spacing.2') theme('spacing.3');
    box-sizing: border-box;
    margin-top: theme('spacing.1');
    border-radius: theme('borderRadius.2');

    &:hover,
    &[data-selected='true'] {
      font-weight: 600;
      background-color: theme('colors.secondary50');
      color: theme('colors.secondary500');
    }
  }
}

.dateRangePicker {
  :global {
    .rdp-months {
      margin-left: 192px;
    }
  }
}
