.mainContainer {
  border-radius: theme('borderRadius.8');
}

.avatarContainer {
  display: flex;
  flex-direction: row;
  position: relative;
  width: fit-content;
}

.avatar {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  [data-shape='square']& {
    border-radius: theme('borderRadius.1');
  }

  [data-shape='circle']& {
    border-radius: theme('borderRadius.8');
  }

  [data-avatar-variant='image']& {
    background-color: theme('colors.tertiary300');
  }

  [data-avatar-variant='text']& {
    background-color: #cccccc;
  }

  [data-avatar-variant='collapse']& {
    background-color: theme('colors.tertiary50');
  }

  [data-size='sm'] & {
    width: 32px;
    height: 32px;
  }

  [data-size='md'] & {
    width: 40px;
    height: 40px;
  }

  [data-size='lg'] & {
    width: 64px;
    height: 64px;
  }

  [data-size='group'] & {
    width: 24px;
    height: 24px;
    margin-left: -4px;
    box-shadow: 0px 0px 0px 1px white;

    &:first-child {
      margin-left: 0;
    }
  }
}

.onlineStatus {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 10;
  background-color: theme('colors.success500');
  border-radius: theme('borderRadius.8');
  box-shadow: 0 0 0 2px white;

  [data-online='false']& {
    display: none;
  }

  [data-size='sm'] & {
    width: 6px;
    height: 6px;
  }

  [data-size='md'] & {
    width: 10px;
    height: 10px;
  }

  [data-size='lg'] & {
    width: 14px;
    height: 14px;
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  [data-size='group'] & {
    width: 13.33px;
    height: 13.33px;
  }

  [data-size='sm'] & {
    width: 13.33px;
    height: 13.33px;
  }

  [data-size='md'] & {
    width: 20px;
    height: 20px;
  }

  [data-size='lg'] & {
    width: 33.33px;
    height: 33.33px;
  }
}

.iconText {
  color: #949494;
  [data-size='sm'] &,
  [data-size='group'] & {
    @apply text-caption-sm-semibold;
  }

  [data-size='md'] & {
    @apply text-body-sm-semibold;
  }

  [data-size='lg'] & {
    @apply text-body-lg-semibold;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: theme('borderRadius.8');
}
