.mainContainer {
  flex: auto;
  height: 100%;
  padding: theme(spacing.5);
  background-color: theme(colors.tertiary25);
  border: 1px theme(colors.tertiary50) solid;
}

.imageViewport {
  position: relative;
  height: 100%;
  aspect-ratio: 7/3;
  margin-bottom: theme(spacing.5);
  background-color: theme(colors.tertiary100);
  overflow: hidden;
}

.prevButton,
.nextButton {
  z-index: 10;
}

.prevButton {
  position: absolute;
  left: theme(spacing.2);
  top: 50%;
  transform: translateY(-50%);
}

.nextButton {
  position: absolute;
  right: theme(spacing.3);
  top: 50%;
  transform: translateY(-50%);
}

.imageCollection {
  position: absolute;
  display: inline-flex;
  height: 100%;
  left: 0;
  top: 0;

  transition: transform 0.3s ease-out;
}

.carouselImage {
  height: 100%;
  object-fit: contain;
}

.pageIndicator {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  gap: theme(spacing.2);

  justify-content: center;
  align-items: center;
  margin-bottom: theme(spacing.5);
  z-index: 20;
}

.pageIndicatorItem {
  width: 8px;
  height: 8px;
  border-radius: theme(borderRadius.8);
  background-color: theme(colors.tertiary25);
  cursor: pointer;
}

.pageIndicatorItemActive {
  background-color: theme(colors.primary500);
}

.title,
.subtitle {
  font-family: 'Open Sans';
  margin: 0;
}

.title {
  color: theme(colors.tertiary500);
  @apply text-heading-h3;
}

.subtitle {
  color: theme(colors.tertiary300);
  @apply text-body-lg-regular;
}
